import {
    useState,
    useEffect,
    useContext } from 'react';

import { useLocation } from 'react-router-dom';

import styles from './candidateInfo.module.scss';
import notificationStyles from '../../components/notification/notification.module.scss';

import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import CandidateForms from './candidateForms';
import CandidateResume from './candidateResume';
import ConfirmationMessage from './confirmationMessage';
import Notification from '../../components/notification/notification';
import admirationIcon from '../../../assets/icons/admiration_white.svg';

import { AppContext } from '../../../router/approuter';
import { validateToken } from '../../api/userClient';

const CandidateInfo = () => {
    const appContext = useContext(AppContext);
    let pageLocation = useLocation();

    const [personalInfo, setPersonalInfo] = useState({})
    const [stepIndex, setStepIndex] = useState(0);
    const [notificationData, setNotificationData] = useState({
        notificationStyle: notificationStyles.notification_wrapper
    });
    const [resumeViewFlag, setResumeViewFlag] = useState(!localStorage.getItem("userNew"))
    const notification = <Notification data={ notificationData } />;

    const displayNotification = (message, isWarn, timer, isErrorConection) => {
        window.scrollTo(0, 0);
        const style = isErrorConection ? notificationStyles.notification_wrapper_error : (isWarn ? notificationStyles.notification_wrapper_warning : notificationStyles.notification_wrapper);
        const icon = isWarn ? admirationIcon : undefined;
        setNotificationData({
            notificationStyle: ( `${ style } ${notificationStyles.active}` ),
            message: message,
            icon: icon,
            isDisplay: true,
            isErrorConection: isErrorConection
        });

        if( !isErrorConection && timer > 0 ) {
            setTimeout(() => {
                setNotificationData({
                    notificationStyle: style,
                    message: message,
                    icon: icon,
                    isDisplay: false
                })
            }, timer);
        }
    }

    const checkSession = () => {
        appContext.showLoading(true, 'Cargando', styles.no_scroll);
        validateToken(appContext.userData.token).then((response) => {
            appContext.showLoading(false, '', styles.no_scroll);
            if( response.status === 200 && response.data.code === 200 ) {
                setStepIndex(8);
            } else {
                appContext.setUserData(null);
                localStorage.removeItem('userNew');
                localStorage.removeItem('userInfo');
                return appContext.navigate("/login");
            }
        }).catch((error) => {
            appContext.showLoading(false, '', styles.no_scroll);
            if( error.code === 'ERR_NETWORK' ) {
                displayNotification(`${error.message}: Imposible validar sesión, el servicio cuenta con bloqueo CORs`, true, 5000, false);
            } else if( error.response.status === 400 && error.response.data.code === 30005 && error.response.data.msg === 'Token inválido.' ) {
                appContext.setUserData(null);
                localStorage.removeItem('userNew');
                localStorage.removeItem('userInfo');
                return appContext.navigate("/login");
            }
        })
    }

    const candidateForm = <CandidateForms 
        stepIndex={stepIndex}
        setStepIndex={setStepIndex}
        displayNotification={ displayNotification }
        resumeViewFlag={ resumeViewFlag }
        personalInfo={ personalInfo }
        setPersonalInfo={ setPersonalInfo }
        appContext={ appContext } />;

    useEffect(() => {
        // regresando a login si no ha iniciado sesión
        if (!appContext.userData) {
            return appContext.navigate("/login");
        } else if(resumeViewFlag) {
            checkSession();
        } else {
            setPersonalInfo({
                firstName: appContext.userData.userProfile.firstName,
                lastName: appContext.userData.userProfile.lastName,
                email: appContext.userData.userProfile.email
            })
        }
    }, [])

    const renderLoading = () => appContext.loadingData.flag && (
        <div className={ styles.loading_container }>
            <div className={ styles.spinner_container }>
                <div className={ styles.spinner }></div>
                <p>{ appContext.loadingData.loadingLabel }</p>
            </div>
        </div>
    )

    return(
        <div className={ `${styles.modal_wrapper} ${appContext.modalFlag ? styles.modal_active : ''}` }>
            { renderLoading() }
            { appContext.modalFlag && appContext.modal }
            <Header
                userData={ appContext.userData }
                navigate={ appContext.navigate }
                setUserData={ appContext.setUserData }
                modalFlag={ appContext.modalFlag }
                setModalFlag={ appContext.setModalFlag }
                setModalContent={ appContext.setModalContent }
                stepIndex={ stepIndex }/>
            <div
                className={ appContext.isDesktopOrLaptop ? styles.candidate_info : styles.candidate_info_mobile }
                style={{ background: stepIndex === 9 ? 'linear-gradient(131.35deg, rgba(176, 98, 253, 0.05) 2.12%, rgba(110, 172, 231, 0.17) 99.1%)' : 'white'  }}
            >
                { notification }
                <div className={ styles.wrapper }>
                    {
                        stepIndex <= 7 && candidateForm
                    }
                    {
                        stepIndex === 8 && (
                            <CandidateResume
                                setStepIndex={setStepIndex}
                                setResumeViewFlag={ setResumeViewFlag }
                                personalInfo={ personalInfo }
                                setPersonalInfo={ setPersonalInfo }
                                displayNotification={ displayNotification }
                            />)
                    }
                    {
                        stepIndex === 9 && (
                            <ConfirmationMessage
                                personalInfo={ personalInfo }
                                setStepIndex={setStepIndex} />
                        )
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CandidateInfo