import {
    useState,
    useContext, 
    useEffect} from 'react';

import styles from './header.module.scss';

import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow_down.svg';


import logoutIcon from '../../../assets/icons/logout.svg';
import profileIcon from '../../../assets/icons/profile.svg';
import menuMobileIcon from '../../../assets/icons/icon_menu_mobile.svg';
import menuMobileIconClose from '../../../assets/icons/icon_menu_mobile_close.svg';
import { AppContext } from '../../../router/approuter';

const Header = (
    {
        userData,
        navigate,
        setUserData,
        modalFlag,
        setModalFlag,
        setModalContent,
        stepIndex
    }
) => {

    const appContext = useContext(AppContext);
    const [menuFlag, setMenuFlag] = useState(false)
    const cookieName = process.env.REACT_APP_COOKIE_NAME;
    const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;
    const careersUrl = process.env.REACT_APP_CAREERS_LANDING;

    useEffect(() => {
        if (userData && !userData.hasOwnProperty('userProfile')) {
            const logOutCookieName = process.env.REACT_APP_LOGOUT_COOKIE_NAME;
            const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;
            const sessionCookie = process.env.REACT_APP_COOKIE_NAME;
            localStorage.removeItem('userInfo');
            setUserData(null);
            navigate("/login");
            document.cookie = logOutCookieName + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=" + cookieDomain + ";path=/;";
            document.cookie = sessionCookie + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=" + cookieDomain + ";path=/;";
        }
    }, [])

    const logOutActions = () => {
        localStorage.removeItem('userNew');
        localStorage.removeItem('userInfo');
        setUserData(null)
        setModalFlag(false)
        navigate("/login")
        appContext.setModalFull(false);
        // borrando la cookie
        document.cookie = cookieName + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=" + cookieDomain + ";path=/;";
    }

    const codeRecoveryErrorMessage = () => {
        return (
            <div className={ styles.error_modal }>
                <div className={ styles.wrapper }>
                    <p className={ styles.title }>Si cierras sesión ahora, los cambios que hayas hecho en esta sección no serán guardados.</p>
                    <p>
                        Regresa y presiona "Guardar cambios" para guardar los cambios realizados en esta sección.
                        Si no quieres guardarlos, presiona "Cerrar sesión sin guardar".
                    </p>
                    <div className={ styles.buttons }>
                        <button className={ styles.cancel } onClick={ () => setModalFlag(false) }>Regresar</button>
                        <button onClick={() => logOutActions() }>Cerrar sesión sin guardar</button>
                    </div>
                </div>
            </div>
        )
    }

    const handleLogOutClick = () => {
        if (stepIndex === 8) {
            logOutActions();
        } else {
            setModalFlag(!modalFlag);
            appContext.setModalFull(false);
            setModalContent(codeRecoveryErrorMessage);
        }
    }

    const handleProfileClick = () => {
        localStorage.removeItem('userNew');
        window.location.href = "/candidate-info";
    }

    const handleVacancyClick = () => {
        // window.location.href = careersUrl + "open-positions"
        window.location.href = "/signup"
    }

    const renderMenu = () => (
        <div className={ styles.header }>
            <div className={ styles.wrapper}>
                <div className={ styles.header_logo}>
                    <a href="https://www.mystratis.com" target="_blank">
                        <img src="https://www.mystratis.com/wp-content/themes/stratis/images/logo.svg" alt="Stratis" />
                    </a>
                </div>
                <div className={ styles.menu }>
                    <a href={ careersUrl }>
                        Inicio
                        <div className={ styles.decorator_wrapper}>
                            <div className={ styles.decorator }></div>
                        </div>
                    </a>
                    <a href={ careersUrl + "open-positions" }>
                        Vacantes
                        <div className={ styles.decorator_wrapper}>
                            <div className={ styles.decorator }></div>
                        </div>
                    </a>
                    <a href={ careersUrl + "white-papers-landing" }>
                        Art&iacute;culos
                        <div className={ styles.decorator_wrapper}>
                            <div className={ styles.decorator }></div>
                        </div>
                    </a>
                    <a href={ careersUrl + "newsletter/subscribe" }>
                        Suscribirse al Newsletter
                        <div className={ styles.decorator_wrapper}>
                            <div className={ styles.decorator }></div>
                        </div>
                    </a>
                </div>
                {
                    (userData && userData.userProfile ) ? (
                        <div className={ styles.logout_wrapper }>
                            <button onClick={ () => setMenuFlag(!menuFlag)}>
                                <p>Hola, <span>{ userData.userProfile.firstName }</span></p>
                                <ArrowIcon />
                            </button>
                            <div className={ styles.menu_wrapper + " " + ((menuFlag) ? styles.active : undefined) }>
                                <button onClick={() => handleProfileClick()}>
                                    <img src={ profileIcon } alt="" />
                                    <p>Mi perfil</p>
                                </button>
                                <button onClick={ () => handleLogOutClick() } >
                                    <img src={ logoutIcon } alt="" />
                                    <p>Cerrar Sesi&oacute;n</p>
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className={ styles.cat}>
                            <button onClick={() => handleVacancyClick() }>&Uacute;nete a Careers Stratis</button>
                            <a className={ styles.login_link } href="/login">
                                Inicia Sesión
                                <div className={ styles.decorator_wrapper}>
                                    <div className={ styles.decorator }></div>
                                </div>
                            </a>
                        </div>
                    )
                }
            </div>
        </div>
    )

    const styleShow = {
        display: menuFlag ? 'flex' : 'none'
    }

    const renderMenuMobile = () => (
        <div className={ styles.header_mobile }>
            <div className={ styles.wrapper}>
                <a href="https://www.mystratis.com" target="_blank">
                    <img src="https://www.mystratis.com/wp-content/themes/stratis/images/logo.svg" alt="Stratis" />
                </a>
                <img src={ menuFlag ? menuMobileIconClose : menuMobileIcon } alt="" onClick={ () => setMenuFlag(!menuFlag) } />
            </div>
            <div className={ styles.menu_wrapper } style={ styleShow }>
                <p onClick={() => handleProfileClick()}>Mi perfil</p>
                <p onClick={ () => handleLogOutClick() }>Cerrar Sesi&oacute;n</p>
            </div>
        </div>
    )

    return appContext.isDesktopOrLaptop ? renderMenu() : renderMenuMobile();
}

export default Header;