import styles from '../signup.module.scss';

import signUpImage from '../../../../assets/img/validation_success.png';

const ConfirmationMessage = (props) => {

    const handleInfoButtonClick = () => {
        if(props.signUpInfo.loginFailed) {
            props.appContext.navigate("/login")
        } else {
            localStorage.setItem('userNew', 'true');
            props.appContext.navigate("/candidate-info");
        }
    }

    return (
        <div className={ styles.message_wrapper + " " + ( props.signUpInfo.loginFailed ? styles.padded_down : undefined )  }>
            <div className={ styles.message_title_wrapper } >
                <div className={ styles.text_wrapper }>
                    <div className={ styles.main_text }>
                        {
                            props.signUpInfo.loginFailed ? (
                                <p>Hemos validado tu direcci&oacute;n de correo exitosamente.</p>
                            ) : (
                                <p>Hola, { props.signUpInfo.name }. Tu direcci&oacute;n de correo ha sido validada exitosamente.</p>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className={ styles.instructions_wrapper }>
                {
                    props.signUpInfo.loginFailed ? (
                        <p>Inicia sesión para comenzar tu proceso de registro.</p>
                    ) : (
                        <>
                            <p>
                                Para continuar, te pedimos contestar algunas preguntas acerca de tus conocimientos y experiencia profesional. 
                                Esto nos ayudar&aacute; a conocer un poco m&aacute;s sobre ti y tus intereses. 
                            </p>
                            <p>
                                En caso de que necesites hacer una pausa, podr&aacute;s guardar tu progreso y continuar despu&eacute;s.
                            </p>
                            <p>Presiona el bot&oacute;n <b>&quot;Crear mi perfil&quot;</b> para avanzar.</p>
                        </>
                    )
                }
            </div>
            <div className={ styles.buttons_wrapper }>
                <button onClick={() => handleInfoButtonClick() }>{
                    props.signUpInfo.loginFailed ? "Iniciar sesión" : "Crear mi perfil"
                }</button>
            </div>
            <div className={ styles.message_img}>
                { props.appContext.isDesktopOrLaptop && (<img src={ signUpImage } alt="" />) }
            </div>
        </div>
    )
}
export default ConfirmationMessage;