import { 
    useState,
    useEffect,
    useContext } from 'react';
import { useLocation } from 'react-router-dom';

import styles from '../signup/signup.module.scss';
import stylesCandidate from '../candidates/candidateInfo.module.scss';

import signUpImage from '../../../assets/img/login.png'

import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';

import { AppContext } from '../../../router/approuter';


// importando los validadores del formulario
import { 
    validateNotNull,
    validateEmail } from '../../core/validators';

import {ReactComponent as HidePassIcon} from '../../../assets/icons/hide_password.svg';
import {ReactComponent as ShowPassIcon} from '../../../assets/icons/view_password.svg';
import { VerificationModal } from '../signup/components/verificationModal';
import { doLogin } from '../../api/userClient';

const LogIn = () => {

    const appContext = useContext(AppContext)
    let pageLocation = useLocation();

    const careersUrl = process.env.REACT_APP_CAREERS_LANDING;
    const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;
    const cookieName = process.env.REACT_APP_COOKIE_NAME;
    const [loginInfo, setLoginInfo] = useState({platformId: 1005000007})
    const [errors, setErrors] = useState(false)
    const [passwordVisibilityFlag, setPasswordVisibilityFlag] = useState(true)

    const validationFields = {
        username: [validateEmail, validateNotNull],
        password: [validateNotNull]
    }


    useEffect(() => {
        if (appContext.userData) {
            return appContext.navigate("/candidate-info");
        }
    }, [])


    const handleFormChange = ({target}) => {
        setLoginInfo((prevData) => ({...prevData, [target.name]: target.value}) )
    }

    const validateRequest = () => {
        // creando el objeto de errores
        const errorObject = {};
        var helper;
        for(const field in validationFields) {
            for (const validator in validationFields[field]) {
                helper = validationFields[field][validator](loginInfo[field])
                if (helper) {
                    errorObject[field] = helper
                }
            }
        }
        return errorObject
    }

    const onClickVerificyCode = () => {
        appContext.setModalFlag(false)
        appContext.navigate("/signup");
    }

    const onSubmitLogin = () => {
        appContext.showLoading(true, 'Cargando', stylesCandidate.no_scroll);
        doLogin(loginInfo).then(({status, data}) => {
            appContext.showLoading(false, '', stylesCandidate.no_scroll);
            if( status === 200 && data && data.code && data.code === 100 ) {
                const { result } = data;
                const userInfo = {
                    token: result.authInfo.accessToken,
                    userProfile: {
                        ...result.userInfo,
                        ...result.talentInfo,
                        id: result.userInfo.userId,
                        email: loginInfo.username,
                    }
                }
                localStorage.setItem('userInfo', JSON.stringify(userInfo));
                appContext.setUserData(userInfo)
                const currentDate = new Date();
                currentDate.setFullYear(currentDate.getFullYear() + 1)
                document.cookie = `${cookieName}=${result.userInfo.firstName}|${result.userInfo.lastName}|${result.userInfo.userId}|${result.talentInfo.talentId}|${result.authInfo.accessToken};expires=${currentDate};domain=${cookieDomain};path=/;`;
                // revisando si reenvía a careers
                if(pageLocation?.search?.includes("careersRedirect")) {
                    appContext.showLoading(false, '', stylesCandidate.no_scroll);
                    window.location.href = careersUrl;
                    return null;
                } else {
                    /* if (response.data.talentId) {
                        window.location.href = careersUrl;
                    } else { */
                    setTimeout(() => {
                        appContext.showLoading(false, '', stylesCandidate.no_scroll);
                        appContext.navigate("/candidate-info");
                    }, 1000);
                    // }
                }
            } else if( status === 202 && data && data.code && data.code === 401 ) {
                appContext.setModalContent( <VerificationModal onClickVerificyCode={onClickVerificyCode} /> )
                    appContext.setModalFlag(true)
                    appContext.setSignUpInfo({
                        email: loginInfo.username,
                        password: loginInfo.password
                    });
                    appContext.setStepSignUpIndex(1);
            } else if( status === 202 && data && data.code ) {
                setErrors({
                    login: [data.code === 403 || data.code === 404 ? "Usuario no válido. Crea una cuenta para ingresar." : "Usuario o contraseña incorrectos"],
                    username: [""],
                    password: [""]
                })
            }
        }).catch( error => {
            appContext.showLoading(false, '', stylesCandidate.no_scroll);
            if( error.code === 'ERR_NETWORK' ) {
                appContext.displayNotification(null, true, true);
            } else {
                setErrors({
                    login: [error.message],
                    username: [""],
                    password: [""]
                })
            }
        });
    }

    const handleSubmit = event => {
        appContext.displayNotification(null, true, 'hide');
        event.preventDefault();
        const validationErrors = validateRequest();
        // en caso de que no haya errores de validación se hace la petición
        if (Object.keys(validationErrors).length === 0) {
            onSubmitLogin();
        } else {
            setErrors(validationErrors)
        }
    }

    const renderLoading = () => appContext.loadingData.flag && (
        <div className={ stylesCandidate.loading_container }>
            <div className={ stylesCandidate.spinner_container }>
                <div className={ stylesCandidate.spinner }></div>
                <p>{ appContext.loadingData.loadingLabel }</p>
            </div>
        </div>
    )

    return  (
        <div className={ `${styles.modal_wrapper} ${appContext.modalFlag ? styles.modal_active : ''}` }>
            { renderLoading() }
            { appContext.modalFlag && appContext.modal }
            <Header />
            <div className={ styles.signup }>
                {
                    appContext.notification
                }
                <div className={ appContext.isDesktopOrLaptop ? styles.wrapper : styles.wrapper_mobile }>
                    <div className={ styles.text_wrapper }>
                        <div className={ styles.main_text + " " +( appContext.isDesktopOrLaptop ? styles.login : "") }>
                            <p>
                                {
                                    appContext.isDesktopOrLaptop ?
                                    'Bienvenido/a. Inicia sesión para postularte a las oportunidades que tenemos para ti.'
                                    :
                                    'Inicia sesión para postularte a las oportunidades que tenemos para ti.'
                                }
                            </p>
                        </div>
                    </div>
                    <div className={ styles.form_wrapper }>
                        <div className={ styles.form_column }>
                            <form className={ styles.form } onSubmit={(event) => handleSubmit(event) }>
                                <div className={ styles.inputs}>
                                    <div className={ styles.input_field }>
                                        <label>Correo electr&oacute;nico*</label>
                                        <div>
                                            <input className={ Object.keys(errors).includes("username") ? styles.error : undefined }
                                                name="username" type="text" onChange={ (event) => handleFormChange(event) } />
                                            {
                                                Object.keys(errors).includes("username") && (
                                                    <p className={ styles.error_message }>{ errors.username[0] }</p>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className={ styles.input_field }>
                                        <label>Contrase&ntilde;a*</label>
                                        <div className={ styles.password_container + " " + (Object.keys(errors).includes("password") ? styles.error : undefined)}>
                                            <input type={ passwordVisibilityFlag ? "password" : "text" } name="password" onChange={ (event) => handleFormChange(event) } />
                                            <button type='button' onClick={(() => setPasswordVisibilityFlag(!passwordVisibilityFlag))}>
                                                { passwordVisibilityFlag ? <HidePassIcon /> : <ShowPassIcon /> }
                                            </button>
                                        </div>
                                        {
                                            Object.keys(errors).includes("password") && (
                                                <p className={ styles.error_message }>{ errors.password[0] }</p>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={ styles.buttons }>
                                    <a href="/password-recovery">Olvid&eacute; mi contrase&ntilde;a</a>
                                    {
                                        Object.keys(errors).includes("login") && (
                                            <p className={ styles.error_message }>{ errors.login[0] }</p>
                                        )
                                    }
                                    <button type="submit">Iniciar sesi&oacute;n</button>
                                    <hr />
                                    <div className={ styles.login_links}>
                                        <p>¿No tienes una cuenta?</p>
                                        <a href="/signup">Crea una cuenta aqu&iacute;</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {
                            appContext.isDesktopOrLaptop && (
                                <div className={ styles.image_login }>
                                    <img src={ signUpImage } alt="" />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            { appContext.isDesktopOrLaptop && <Footer /> }
        </div>
    )

}

export default LogIn